<template>
  <div  :style="explorerStyle">
    <div class="sticky top-0 z-40 px-4 py-2 border-b border-gray-200 sm:px-6">
      <h3 class="text-lg font-medium leading-6">
        Select an available asset
      </h3>
      <p class="max-w-2xl mt-1 text-sm leading-5 text-gray-500 ">
        {{ typeSelectNoun }}
      </p>
    </div>

      <iframe
      id="change_asset_drive"
      class="w-full min-h-50vh"
      :src="driveModalUrl(null, 'select', 'replace_asset')"
    />

  </div>
</template>
<script>
import iframeCommunication from '@mixins/iframe-communication-mixin.js'

const AssetsExplorer = () => import('@components/assets/assets-explorer.vue')

export default {
  name: 'ModuleAssetSelectFromDrive',

  mixins: [iframeCommunication],

  components: {
    AssetsExplorer
  },

  props: ['type', 'mod', 'timeline'],

  mounted() {
    window.addEventListener('message', (event) => {
        if (event.data.event === 'replace_asset') {
          this.onSelectAsset({ type: 'image', id: event.data.assetId })
        }
    })
  },

  computed: {

    isSceneModule () {
      return this.type === 'scene'
    },

    restrictedActionTypes () {
      if (this.isSceneModule) return ['scene']

      return ['image', 'video', 'composition']
    },

    typeSelectNoun () {
      if (this.isSceneModule) return 'Choose a scene to apply to the sequence.'
      return 'Choose image or video content to apply to the timeline module.'
    },

    explorerStyle () {
      return 'min-height: 50vh'
      // @todo - this should be calculated based on the page height
      // we want to set it explictly to stop any jumps when navigating between folders
    },

    assetId () {
      return this.$route.params.uuid
    },

    asset () {
      return this.$store.getters['assets/getAssetById'](this.assetId)
    },

    projectId () {
      return this.asset.project_id
    },

    assets: function () {
      return this.$store.getters['assets/assets']
    },

    project: function () {
      return this.$store.getters['project/project']
    },

    activeFolder: function () {
      return this.$store.getters['assets/activeSet'](this.projectId)
    }
  },

  methods: {
    onSelectAsset (asset) {
      if (this.restrictedActionTypes.includes(asset.type)) {
        // Get the asset. This might incur an api call to fetch the asset details
        this.$store.dispatch('assets/loadAsset', { id: asset.id })
          .then(item => {
            this.setModuleAsset(item)

            this.$emit('close')
          })
          .catch((err) => {
            console.log('Failed to load asset :<', err)
          })
      }
    },

    setModuleAsset (asset) {
      if (this.timeline) {
        this.timeline.updateModuleAsset(this.mod, asset)
      }
    }
  }

}
</script>
