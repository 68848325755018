export default {
  data () {
    return {
      newDiv: null
    }
  },

  mounted () { },

  computed: {
    driveUrl () {
      return process.env.VUE_APP_DRIVE_BASEURL
    },

    driveTabUrl () {
      return (
        this.driveUrl +
        'embed/' +
        this.driveId +
        '?action=add&types=image,video,composition'
      )
    },

    driveId () {
      return this.$route.query.drive
    }
  },

  methods: {
    driveModalUrl (types, action, returnAction) {
      let filterTypes = 'image,video,composition'
      let filterAction = ''
      let filterActionReturn = 'return_asset'
      if (types) {
        filterTypes = types
      }
      if (action) {
        filterAction = action
      }
      if (returnAction) {
        filterActionReturn = returnAction
      }
      return (
        this.driveUrl +
        'embed/' +
        this.driveId +
        '?types=' +
        filterTypes +
        '&action=' +
        filterAction +
        '&return_action=' +
        filterActionReturn
      )
    },

    registerIframeDragListeners (uuid) {
      let self = this
      let doc = document
      window.addEventListener('message', (event) => {
        if (event.data.event === 'dragging') {
          let drive = doc.getElementById('drive').getBoundingClientRect()
          let el = doc.elementFromPoint(
            event.data.x + drive.x,
            event.data.y + drive.y - 2
          )

          if (el && el.id === uuid) {
            self.isDragTarget = true
          } else {
            self.isDragTarget = false
          }
        } else if (event.data.event === 'drag_end') {
          // remove drag icon
          let drive = doc.getElementById('drive').getBoundingClientRect()
          let el = doc.elementFromPoint(
            event.data.x + drive.x,
            event.data.y + drive.y - 2
          )
          let rect = el.getBoundingClientRect()

          if (el && el.id === uuid) {
            this.handleAssetDrop(event.data.assetId, {
              offsetX: event.data.x + drive.x - rect.x
            })
          }
        }
      })
    },

    registerIframeDragEmptyListeners () {
      let doc = document
      window.addEventListener('message', (event) => {
        if (event.data.event === 'drag_start') {
          this.newDiv = document.createElement('div')
          this.newDiv.style.position = 'absolute'
          this.newDiv.style.zIndex = '999999'
          this.newDiv.insertAdjacentHTML('beforeend', event.data.ghost)

          doc.body.appendChild(this.newDiv)
        } else if (event.data.event === 'dragging') {
          let drive = doc.getElementById('drive').getBoundingClientRect()
          this.newDiv.style.top = event.data.y + drive.y + 'px'
          this.newDiv.style.left = event.data.x + drive.x + 'px'
        } else if (event.data.event === 'drag_end') {
          this.newDiv.remove()
        } else if (event.data.event === 'return_asset') {
          let data = { offsetX: 0 }
          if (event.data.meshName) {
            data.target = event.data.meshName
          }
          this.handleAssetDrop(event.data.assetId, data)
        }
      })
    }
  }
}
