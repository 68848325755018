<template>
  <div
    :class="[show ? 'left-0' : '-left-full']"
    class="absolute top-0 flex flex-col items-center justify-between w-full h-screen bg-overlay-gray"
    style="z-index:999;"
    @click.prevent="close"
  >
    <div
      class="w-full my-0 overflow-y-auto text-gray-100 rounded-lg shadow sm:my-16 md:my-24 mobile:rounded-lg"
      :class="{
        'max-w-xl' : modalSize === 'sm',
        'mobile:max-w-2xl' : modalSize === 'md',
        'mobile:max-w-5xl' : modalSize === 'lg',
        'h-full mobile:max-w-5xl' : modalSize === 'full'}"
      @click.stop
    >
      <div
        class="flex justify-between w-full h-10 px-8 py-2 text-sm font-bold text-white uppercase bg-transparent cursor-pointer mobile:hidden hover:underline"
        @click.prevent="close"
      >
        <div />
        <div><fa-icon icon="xmark" /></div>
      </div>

        <ModuleAssetSelectFromDrive
        class="bg-gray-900 rounded-lg"
          v-bind="props"
          @close="close"
        />

    </div>
  </div>
</template>
<script>
import ModuleAssetSelectFromDrive from '@components/editor/timelineMerged/modules/blocks/ModuleAssetSelectFromDrive'
export default {
  name: 'DriveModal',
  components: {
    ModuleAssetSelectFromDrive
  },
  data () {
    return {
      show: false,
      component: null,
      props: null,
      size: null
    }
  },

  computed: {
    modalSize () {
      switch (this.size) {
        case 'sm':
          return 'sm'
        case 'md':
          return 'md'
        case 'full':
          return 'full'
        case 'lg':
        default:
          return 'lg'
      }
    }
  },

  mounted () {
    this.listenForEvents()
  },

  methods: {
    close () {
      this.show = false
      this.component = null
      this.props = null
    },

    listenForEvents () {
      this.$bus.$on('keyboard:event:escape', event => {
        this.close()
      })

      this.$bus.$on('overlay:drivemodal', event => {
        this.showModal(event)
      })
    },

    reset () {
      this.component = null
      this.props = null
      this.show = false
      this.size = null
    },

    showModal (event) {
      this.reset()

        if (event.props !== undefined) {
          this.props = event.props
        }

        if (event.size !== undefined) {
          this.size = event.size
        }

        this.show = true
    }
  }
}
</script>
